.selected-candidate {
    color: firebrick;
    background: #fff;
}

.selected-client {
    color: goldenrod;
    background: #fff;
}

.selected-job {
    color: rgba(142, 68, 173, 1);
    background: #fff;
}