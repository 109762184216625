/*!
 * Font Awesome Pro 5.0.7 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  src: url("../webfonts/fa-brands-400.eot");
  src: url("../webfonts/fa-brands-400.eot?#iefix") format("embedded-opentype"), url("../webfonts/fa-brands-400.woff2") format("woff2"), url("../webfonts/fa-brands-400.woff") format("woff"), url("../webfonts/fa-brands-400.ttf") format("truetype"), url("../webfonts/fa-brands-400.svg#fontawesome") format("svg"); }

.fab {
  font-family: 'Font Awesome 5 Brands'; }

/*# sourceMappingURL=fa-brands.css.map */

.facebook-color {
    color: #4267B2;
}

.linkedin-color {
    color: #0073B0;
}

.twitter-color {
    color: #1DA1F2;
}

.social-media-container svg {
    margin: 0 15px;
}
.social-media-container svg:hover {
    cursor: pointer;
}