
.figure {
    font-size: 2.2rem;
    line-height: 2rem;
    font-weight: 700;
    padding-top: 1rem;
}

.count-type {
    font-size: 1.05rem;
    line-height: .9rem;
    text-align: right;
}

.left-card-content {
    display: inline-block;
    padding: .5rem 2.2rem;
    font-weight: 300;
    vertical-align: middle;
    border-bottom: none;
    text-align: right;
    width: 49%;
    height: 100%;
}

.card {
    margin: 20px 0;
}

.card-header {
    background: #255398;
    color: white;
    border-bottom: rgb(228, 119, 35) 2px solid;
    font-size: 20px;
}

.card-body {
    padding: 10px;
    font-size: 15px;
}

.list-group-flush .list-group-item {
    border-top: .5px solid grey;
}

.modal-header {
    padding: 20px !important;
}

.slide-pane .slide-pane__title {
    font-weight: 700 !important;
}

.line-height-1-5 {
    line-height: 1.5 !important;
}