/*Messagebox */

.divMessageBox {
    background: rgba(0, 0, 0, 0.6);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100000;
}

.MessageBoxContainer {
    background-color: #232323;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 20px;
    position: relative;
    top: 35%;
    width: 100%;
}

.MessageBoxMiddle {
    left: 20%;
    position: relative;
    width: 60%;
}

    .MessageBoxMiddle .MsgTitle {
        font-size: 24px;
        font-weight: 300;
        letter-spacing: -1px;
    }

    .MessageBoxMiddle .pText {
        font-size: 13px;
    }

.MessageBoxContainer input {
    padding: 5px;
    width: 50%;
}

.MessageBoxContainer select {
    padding: 5px;
    width: 50%;
}

.MessageBoxButtonSection {
    height: 30px;
    width: 100%;
}

    .MessageBoxButtonSection button {
        float: right;
        font-size: 14px;
        font-weight: bold;
        margin-right: 7px;
        padding-left: 15px;
        padding-right: 15px;
    }

/* End of MessageBox */

.LoadingBoxContainer {
    background-color: #232323;
    color: white;
    position: relative;
    top: 20%;
    width: 100%;
}

.LoadingBoxMiddle {
    left: 20%;
    padding: 10px;
    position: relative;
    width: 50%;
}

.LoadingBoxContainer .MsgTitle {
    font-size: 26px;
}

.LoadingBoxContainer .pText {
    font-size: 13px;
}

#LoadingPoints {
    position: absolute;
}

/* bigBoxes */

.bigBox {
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    background-color: #004d60;
    border-left: 5px solid rgba(0, 0, 0, 0.15);
    bottom: 10px;
    box-sizing: content-box;
    color: white;
    height: 150px;
    overflow: hidden;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    position: fixed;
    right: 10px;
    width: 390px;
    z-index: 99999;
}

    .bigBox span {
        display: block;
        font-size: 17px;
        font-weight: 300;
        letter-spacing: -1px;
        padding: 5px 0 !important;
    }

    .bigBox p {
        font-size: 13px;
        margin-top: 10px;
    }

#divMiniIcons {
    bottom: 180px;
    float: right;
    position: fixed;
    right: 10px;
    width: 415px;
    z-index: 9999;
}

.botClose {
    cursor: pointer;
    display: block;
    font-size: 18px;
    height: 16px;
    opacity: .5;
    position: absolute;
    right: 10px;
    top: 15px;
    width: 15px;
}

    .botClose:hover {
        opacity: 1;
    }

#divMiniIcons .cajita {
    background-color: #F00;
    color: #FFF;
    cursor: pointer;
    display: block;
    float: right;
    font-size: 17px;
    margin-left: 4px;
    margin-top: 5px;
    padding: 4px 6px;
    text-align: center;
    vertical-align: middle;
}

    #divMiniIcons .cajita:active {
        left: 1px;
        position: relative;
        top: 1px;
    }

.cajita img {
    height: 23px;
    padding-left: 3px;
    padding-top: 3px;
    width: 23px;
}

.bigBox .bigboxicon {
    font-size: 30px;
    left: 6px;
    position: absolute;
    text-align: left;
    top: 120px;
    z-index: 0;
}

.bigBox .bigboxnumber {
    font-size: 25px;
    text-align: right;
    width: 100%;
}

/* End of  bigBoxes */

/* SmallBox */

#divSmallBoxes {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99999999;
}

.SmallBox {
    border: 1px solid transparent;
    color: white;
    overflow: hidden;
    position: absolute;
    right: 5px;
    top: 20px;
    width: 420px;
    z-index: 9999;
}

    .SmallBox:hover {
        border: 1px solid #fff;
        cursor: pointer;
    }

    .SmallBox .foto {
        font-size: 50px;
        left: 17px;
        position: absolute;
    }

    .SmallBox .textoFull {
        float: left;
        padding-left: 20px;
        width: 93%;
    }

    .SmallBox .textoFoto {
        float: left;
        margin: 3px 20px 3px 80px;
        width: 78%;
    }

.miniPic {
    bottom: 8px;
    position: absolute;
    right: 9px;
}

.miniIcono {
    font-size: 20px;
    height: 100%;
}

.SmallBox span {
    display: block;
    font-size: 17px;
    font-weight: 300;
    letter-spacing: -1px;
    margin: 4px 0;
}

.SmallBox p {
    font-size: 13px;
    margin-top: 2px;
}

/* End of SmallBox */

#pageslide {
    -moz-shadow: inset 0 0 0px 0px #222;
    -webkit-box-shadow: inset 0 0 5px 5px #222;
    /* These styles are optional, and describe how the pageslide will look */
    background-color: #004d60;
    box-shadow: inset 0 0 0px 0px #222;
    color: #FFF;
    /* These styles MUST be included. Do not change. */
    display: none;
    height: 100%;
    padding: 20px;
    position: absolute;
    position: fixed;
    top: 0;
    /* Specify the width of your pageslide here */
    width: 305px;
    z-index: 999999;
}

.purehtml {
    color: white;
    font-size: 16px;
}

/* Mobile Phones */

@media screen and (max-width: 450px) and (max-width: 767px) {
    /* SmallBox Responsive */
    #divSmallBoxes {
        position: fixed;
        right: 0;
        top: 0;
        width: 90%;
    }

    .SmallBox {
        width: 95%;
    }

        .SmallBox:hover {
            box-shadow: 0px 0px 10px #888888;
            cursor: pointer;
        }

        .SmallBox span {
            font-size: 16px;
            /*font-weight:bold;*/
        }

        .SmallBox p {
            font-size: 12px;
            margin-top: 2px;
        }

        .SmallBox .textoFull {
            float: left;
            padding-left: 20px;
            width: 93%;
        }

        .SmallBox .textoFoto {
            float: left;
            margin: 3px 20px 3px 80px;
            width: 55%;
        }

    /* End of SmallBox Responsive*/
    /* bigBoxes Responsive */
    .bigBox {
        background-color: #004d60;
        bottom: 10px;
        color: white;
        height: 150px;
        padding-bottom: 5px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        position: fixed;
        right: 10px;
        width: 88%;
        z-index: 99999;
    }

    /* End of  bigBoxes  Responsive */
    /*Messagebox Responsive */
    .divMessageBox {
        background: rgba(0, 0, 0, 0.6);
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 100000;
    }

    .MessageBoxContainer {
        background-color: #232323;
        color: white;
        position: relative;
        top: 25%;
        width: 100%;
    }

    .MessageBoxMiddle {
        left: 0;
        padding: 3px;
        position: relative;
        width: 100%;
    }

        .MessageBoxMiddle .MsgTitle {
            font-size: 22px;
        }

        .MessageBoxMiddle .pText {
            font-size: 13px;
        }

    .MessageBoxContainer input {
        padding: 5px;
        width: 50%;
    }

    .MessageBoxContainer select {
        padding: 5px;
        width: 50%;
    }

    .MessageBoxButtonSection {
        height: 30px;
        width: 100%;
    }

        .MessageBoxButtonSection button {
            float: right;
            margin-right: 5px;
            padding-left: 15px;
            padding-right: 15px;
        }
}
    /* End of MessageBox Responsive */