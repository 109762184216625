@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
.jqvmap-label {
  position: absolute;
  display: none;
  border-radius: 3px;
  background: #292929;
  color: white;
  font-family: sans-serif, Verdana;
  font-size: smaller;
  padding: 3px;
  pointer-events: none; }

.jqvmap-pin {
  pointer-events: none; }

.jqvmap-zoomin, .jqvmap-zoomout {
  position: absolute;
  left: 10px;
  border-radius: 3px;
  background: #000000;
  padding: 3px;
  color: white;
  width: 10px;
  height: 10px;
  cursor: pointer;
  line-height: 10px;
  text-align: center; }

.jqvmap-zoomin {
  top: 10px; }

.jqvmap-zoomout {
  top: 30px; }

.jqvmap-region {
  cursor: pointer; }

.jqvmap-ajax_response {
  width: 100%;
  height: 500px; }

/* #BOOTSTRAP AND MIXINS - Base Unmodified Bootstrap file with theme mixins
========================================================================== */
/*---------------------------------------------------
    SASS ELements (based on LESS Elements 0.9 http://lesselements.com) 
  -------------------------------- -------------------
    LESS ELEMENTS made by Dmitry Fadeyev (http://fadeyev.net)
    SASS port by Samuel Beek (http://samuelbeek.com) 
  ---------------------------------------------------*/
/*------------------------
    Usage

    h1 {
      font-size: rem(32);
    }

    OR:

    h1 {
      font-size: rem(32px);
    }
------------------------*/
/*------------------------
  FADE IN
  e.g. @include fadeIn( 2s );
------------------------*/
/*------------------------
mixin that calculates if text needs to be light or dark
depending on the background color passed.

From this W3C document: http://www.webmasterworld.com/r.cgi?f=88&d=9769&url=http://www.w3.org/TR/AERT#color-contrast

usage:
@include text-contrast($bgcolor)
      
Color brightness is determined by the following formula: 
((Red value X 299) + (Green value X 587) + (Blue value X 114)) / 1000
------------------------*/
/*------------------------
 color factory 
  eg: @include paint($blue-grey-50, bg-blue-grey-50);
------------------------*/
/* backface visibility */
/* generate theme button */
/* #BASE - Base Variable file along with font library, and colors.
========================================================================== */
/*  THEME COLORs
========================================================================== */
/* Looks good on chrome default color profile */
/* looks good in sRGB but washed up on chrome default 
$color-primary:						#826bb0;
$color-success:						#31cb55;
$color-info:						#5e93ec;
$color-warning:						#eec559;
$color-danger:						#dc4b92;
$color-fusion:						darken(desaturate(adjust-hue($color-primary, 5), 80%), 25%); */
/*  Color Polarity
========================================================================== */
/*  PAINTBUCKET MIXER
========================================================================== */
/* the grays */
/* the sapphires */
/* the emeralds */
/* the amethyths */
/* the topaz */
/* the rubies */
/* the graphites */
/*  Define universal border difition (div outlines, etc)
========================================================================== */
/*  MOBILE BREAKPOINT & GUTTERS (contains some bootstrap responsive overrides)
========================================================================== */
/* define when mobile menu activates, here we are declearing (lg) so it targets the one after it */
/* bootstrap reference xs: 0,  sm: 544px, md: 768px, lg: 992px, xl: 1200px*/
/* global var used for spacing*/
/* Uniform Padding variable */
/* Heads up! This is a global scoped variable - changing may impact the whole template */
/*   BOOTSTRAP OVERRIDES (bootstrap variables)
========================================================================== */
/* usage: theme-colors("primary"); */
/* forms */
/*$input-height:							calc(2.25rem + 1px); //I had to add this because the input gruops was having improper height for some reason... */
/* links */
/* checkbox */
/*$custom-file-height-inner:				calc(2.25rem - 1px);*/
/* not part of bootstrap variable */
/* custom checkbox */
/* custom range */
/* select */
/* badge */
/* cards */
/*border radius*/
/* alert */
/* toast */
/* breadcrumb */
/* input button */
/* nav link */
/* nav, tabs, pills */
/* tables */
/* dropdowns */
/* dropdowns sizes */
/* popovers */
/* tooltips */
/* modal */
/* reference guide
http://www.standardista.com/px-to-rem-conversion-if-root-font-size-is-16px/
8px = 0.5rem
9px = 0.5625rem
10px = 0.625rem
11px = 0.6875rem
12px = 0.75rem
13px = 0.8125rem
14px = 0.875rem
15px = 0.9375rem
16px = 1rem (base)
17px = 1.0625rem
18px = 1.125rem
19px = 1.1875rem
20px = 1.25rem
21px = 1.3125rem
22px = 1.375rem
24px = 1.5rem
25px = 1.5625rem
26px = 1.625rem
28px = 1.75rem
30px = 1.875rem
32px = 2rem
34px = 2.125rem
36px = 2.25rem
38px = 2.375rem
40px = 2.5rem
*/
/* Fonts */
/* carousel */
/*  BASE VARS
========================================================================== */
/* font vars below will auto change to rem values using function rem($value)*/
/* 11px   */
/* 12px   */
/* 12.5px */
/* 14px   */
/* 15px   */
/* 16px   */
/* 28px   */
/*  Font Family
========================================================================== */
/*hint: you can also try the font called 'Poppins' by replacing the font 'Roboto' */
/*  ANIMATIONS
========================================================================== */
/* this addresses all animation related to nav hide to nav minify */
/*  Z-INDEX declearation
========================================================================== */
/* we adjust bootstrap z-index to be higher than our higest z-index*/
/*  CUSTOM ICON PREFIX 
========================================================================== */
/*  PRINT CSS (landscape or portrait)
========================================================================== */
/* landscape or portrait */
/* auto, letter */
/*  Common Element Variables
========================================================================== */
/* Z-index decleartion "birds eye view"
========================================================================== */
/*  Components
========================================================================== */
/*  PAGE HEADER STUFF
========================================================================== */
/* colors */
/* height */
/* logo */
/* try not to go beywond the width of $main_nav_width value */
/* you may need to change this depending on your logo design */
/* adjust this as you see fit : left, right, center */
/* icon font size (not button) */
/* search input box */
/* suggestion: #ccced0*/
/* btn */
/* dropdown: app list */
/* badge */
/* COMPONENTS & MODS */
/*  NAVIGATION STUFF

Guide:

aside.page-sidebar ($nav-width, $nav-background)
	.page-logo
	.primary-nav
		.info-card
		ul.nav-menu
			li
				a (parent level-0..., $nav-link-color, $nav-link-hover-color, $nav-link-hover-bg-color, $nav-link-hover-left-border-color)
					icon 
					span
					collapse-sign 
					
				ul.nav-menu-sub-one  
					li
						a ($nav-level-1... $nav-sub-link-height)
							span
							collapse-sign

						ul.nav-menu-sub-two
							li
								a ($nav-level-2... $nav-sub-link-height)
									span

		p.nav-title ($nav-title-*...)


========================================================================== */
/* main navigation */
/* left panel */
/* nav parent level-0 */
/* nav icon sizes */
/* badge default */
/* all child */
/* nav title */
/* nav Minify */
/* when the menu pops on hover */
/* navigation Width */
/* partial visibility of the menu */
/* top navigation */
/* nav Info Card (appears below the logo) */
/* width is auto */
/* nav DL labels for all child */
/* will be pulled to left as a negative value */
/*   MISC Settings
========================================================================== */
/* List Table */
/*   PAGE SETTINGS
========================================================================== */
/*   PAGE BREADCRUMB 
========================================================================== */
/*   PAGE COMPONENT PANELS 
========================================================================== */
/*   PAGE COMPONENT PROGRESSBARS 
========================================================================== */
/*   PAGE COMPONENT MESSENGER 
========================================================================== */
/*   FOOTER
========================================================================== */
/*   GLOBALS
========================================================================== */
/* ACCESSIBILITIES */
/* SHORTCUT BUTTON (appears on bottom right of the page) */
body {
  font-size: 0.8125rem;
  letter-spacing: 0.1px; }

.page-content {
  color: #666666; }

h1, h2, h3, h4, h5, h6 {
  line-height: 1.3;
  font-weight: 400; }

strong {
  font-weight: 500; }

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small {
  font-weight: 300;
  display: block;
  font-size: 0.9375rem;
  line-height: 1.5;
  margin: 2px 0 1.5rem; }

h2 small,
h3 small,
.h2 small,
.h3 small {
  font-size: 0.9375rem; }

h4 small,
.h4 small {
  font-size: 0.875rem; }

h5 small,
h6 small,
.h5 small,
.h6 small {
  font-size: 0.8125rem; }

/* contrast text */
.text-contrast {
  color: #333333; }

/* text-gradient */
.text-gradient {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(25%, #1B4B79), color-stop(50%, #62468d), color-stop(75%, #0c7cd5), to(#0960a5));
  background: linear-gradient(180deg, #1B4B79 25%, #62468d 50%, #0c7cd5 75%, #0960a5 100%);
  color: #dd5293;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: none; }

/* looking for font size? Check _helpers.scss */
/* PLACEHOLDER 
============================================= 

EXAMPLE:

%bg-image {
		width: 100%;
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;
}

.image-one {
		@extend %bg-image;
		background-image:url(/img/image-one.jpg");
}

RESULT:

.image-one, .image-two {
		width: 100%;
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;
}

*/
/*
%shadow-hover {
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 0 2px rgba(0,0,0,0.24);
	transition: all 0.2s ease-in-out;

	&:hover {
		box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 -1px 6px rgba(0,0,0,0.23);
	}
}
*/
/*%fixed-header-shadow {
	@include box-shadow(0 2px 2px -1px rgba(0,0,0,.1));
}*/
/*  %selected-dot {
		&:before {
			content: " ";
			display: block;
			border-radius: 50%;
			background: inherit;
			background-image: none;
			border: 2px solid rgba(0,0,0,0.2);
			position: absolute;
			top: 15px;
			left: 15px;
			height: 20px;
			width: 20px;
		}
		&:after {
			content: " ";
			height: inherit;
			width: inherit;
			border: 5px solid rgba(0,0,0,0.1);
			position: absolute;
			left: 0;
			top: 0;
			border-radius: 50%;
		} 
	}*/
/* patterns */
.jqvmap-zoomin, .jqvmap-zoomout {
  width: 1.6rem;
  height: 1.6rem;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 3px;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#f5f5f5), to(#f1f1f1));
  background-image: linear-gradient(to top, #f5f5f5, #f1f1f1);
  color: #444;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: none;
          box-shadow: none;
  left: 0;
  top: 0;
  color: #333;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.jqvmap-zoomout {
  top: 1rem; }

.p-0 .jqvmap-zoomin,
.p-0 .jqvmap-zoomout {
  left: 1rem;
  top: 1rem; }

.p-0 .jqvmap-zoomout {
  top: 3rem; }

.jqvmap-bg-ocean {
  background-color: #eafeff; }

/*# sourceMappingURL=jqvmap.bundle.css.map */
