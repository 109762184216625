.editor-textarea {
    min-height: 400px;
    margin: 10px;
    border-top: 1px solid grey;
    padding: 0 10px;
}

.DraftEditor-root {
    height: 400px;
}

.demo-wrapper {
    height: 90%;
}

.height-60vh {
    height: 60vh !important;
}

.height-75vh {
    height: 75vh !important;
}

.h-90 {
    height: 90% !important;
}

.rdw-editor-main pre {
    background: #fff !important;
}