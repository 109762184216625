/*tr {
    height: 90px;
}*/

.mobileTable {
    display: none;
}

.table-hover:hover {
    cursor: pointer;
}

.filter-label {
    display: block;
}

@media screen and (max-width: 767px) {
    .mobileTable {
        display: unset;
    }

    .fullTable {
        display: none;
    }

    .react-bs-table-sizePerPage-dropdown {
        display: none;
    }
}

.react-bootstrap-table-pagination {
    margin: 0;
}

.expand-cell, .expand-cell-header {
    padding: 12px 0 !important;
    text-align: center;
}

.react-bootstrap-table-pagination .page-item.active .page-link {
    background-color: #641A29;
    border-color: #F30079;
    color: #fff;
}

.react-bootstrap-table-pagination .pagination .page-link:hover {
    background-color: #641A29 !important;
    color: #fff;
}

.react-bootstrap-table-pagination .page-link {
    color: #641A29;
}


.organizations-table-header th {
    border-bottom: 2px solid grey !important;
}

.table-bordered th, .table-bordered td {
    border: unset;
    border-bottom: 1px solid #e9e9e9;
}

.table-bordered {
    line-height: 1;
}

.table-bordered th, .table-bordered td {
    border: unset;
    border-bottom: 1px solid #e9e9e9;
}

.table-bordered {
    line-height: 1;
}

/*.btn-group-toggle {
    position: unset !important;
    display: block !important;
    vertical-align: unset !important;
    display: list-item;
    width: 25%;
    display: block;
    width: 100%;*/
    /*height: calc(1.47em + 1rem + 2px);*/
    /*padding: 0.5rem 0.875rem;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.47;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #E5E5E5;
    border-radius: 4px;*/
    /* transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; */
    /*background: white;
    z-index: 1111;
}*/

    .btn-group-toggle .btn-primary {
        background: none !important;
        color: red !important;
        text-decoration: line-through;
        border: unset !important;
        box-shadow: none !important;
        display: block !important;
    }

    .btn-group-toggle .btn-primary.active {
        color: green !important;
        text-decoration: unset !important;
    }

/*.caret {
    margin: 10px 0 10px 5px;
    color: #cccccc;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
    content: "";
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid\9;
}*/

.react-bootstrap-table-pagination .dropdown-menu {
    top: -195px !important;
}