.search-div {
    display: inline-block;
    position: relative;
    width: 15vw;
    min-width: 200px;
}

.top-nav-item-desc {
    letter-spacing: .05rem;
    text-transform: uppercase;
    font-weight: 400;
    font-family: proxima-nova,sans-serif;
    color: #000;
}

.header-icon {
    padding: 1rem 10px;
}

.seperator {
    border: .5px solid lightgrey;
    margin: 10px;
}

.top-nav-item {
}

    .top-nav-item:hover {
        cursor: pointer;
        background: lightgrey;
        color: black;
    }

.add-item {
    border-top: .5px solid lightgrey !important;
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    background-color: #fff;
}

    .add-item:first-child {
        border-top: unset !important;
    }

    .add-item:hover {
        cursor: pointer;
        background-color: lightgrey;
    }

/*@media screen and (max-width: 1180px) {
    .top-nav-item-desc {
        display: none;
    }
}*/

#candidatesDashboardBtn.active, #candidatesDashboardBtn.active:hover {
    background-color: firebrick;
}

.dropdown .dropbtn:hover {
    background-color: rgba(111, 178, 70, .2);
}

@media screen and (max-width: 1450px) {
    span.top-nav-item-desc {
        display: none;
    }
}


/* Dropdown Button */


/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 225px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1000;
}

    /* Links inside the dropdown */
    .dropdown-content li {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
    }

        /* Change color of dropdown links on hover */
        .dropdown-content li:hover {
            background-color: #ddd;
            cursor: pointer;
        }

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
    background-color: rgba(111, 178, 70, .2);
}

.text-icon {
    color: #641A29 !important;
}

.separator {
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 10px;
}

    .separator::before, .separator::after {
        content: '';
        flex: 1;
        border-bottom: 1px solid pink;
    }

    .separator::before {
        margin-right: .25em;
    }

    .separator::after {
        margin-left: .25em;
    }

.secondary-header {
    position: absolute;
    top: 18px;
    left: 0;
    z-index: 1;
    background-color: rgba(255,192,203,.4);
    color: rgb(0, 51, 102) !important;
    border-color: unset !important;
    padding: 10px;
    width: 100%;
}

.active-nav-item {
    background: #fff;
    border-radius: 0px;
}

.nav-stacked {
    display: unset !important;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    height: 100%;
    padding-right: 0;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: none;
    background: #ededed;
}

    .nav-stacked .nav-link.active {
        border-color: rgba(0, 0, 0, 0.1) #fff rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1)
    }

    .nav-stacked.nav-clients .nav-item .nav-link.active:not(:hover) {
        color: #F88F04;
    }

    .nav-stacked.nav-candidates .nav-item .nav-link.active:not(:hover) {
        color: firebrick;
    }

    .nav-stacked.nav-jobs .nav-item .nav-link.active:not(:hover) {
        color: #0F9D58;
    }

    .nav-stacked.nav-organizations .nav-item .nav-link.active:not(:hover) {
        color: #37BBE3;
    }

    .nav-stacked .nav-link:hover {
        border-color: rgba(0, 0, 0, 0.07) transparent rgba(0, 0, 0, 0.07) rgba(0, 0, 0, 0.07);
    }

    .nav-stacked .nav-item {
        margin-right: -1px;
    }

    .nav-stacked.nav-clients .nav-item:hover {
        color: #F88F04;
    }

    .nav-stacked.nav-candidates .nav-item:hover {
        color: firebrick;
    }

    .nav-stacked.nav-jobs .nav-item:hover {
        color: #0F9D58;
    }

    .nav-stacked.nav-organizations .nav-item:hover {
        color: #37BBE3;
    }