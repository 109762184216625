/*!
 * Font Awesome Pro 5.0.7 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  src: url("../webfonts/fa-regular-400.eot");
  src: url("../webfonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("../webfonts/fa-regular-400.woff2") format("woff2"), url("../webfonts/fa-regular-400.woff") format("woff"), url("../webfonts/fa-regular-400.ttf") format("truetype"), url("../webfonts/fa-regular-400.svg#fontawesome") format("svg"); }

.far {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400; }

.fa-2x {
    font-size: 2rem !important;
}

.fa-1x {
    font-size: 1rem !important;
}

.fa-15x {
    font-size: 1.5rem !important;
}

.fa-125x {
    font-size: 1.25rem !important;
}

/*# sourceMappingURL=fa-regular.css.map */
